import _ from 'lodash';

export default function (query) {
  let search = new RegExp(_.escapeRegExp(query.fullTextSearch), "i");
  return true;
  this.filterRows = _.filter(this.rows, (row) => {

    //返回 true 保留该条数据，返回false过滤该条数据

    //根据查询字段筛选，如果有不符合的排除该条数据//////////////////////////
    for (let column in query.columns) {
      let qcc = query.columns[column];
      let rc = row[column];
      let rcf = row[`${column}_format`];
      //过滤字段时需要考虑 format 字段
      if (qcc instanceof Array) {
        if (
          !(_.isEmpty(qcc) || _.includes(qcc, rc) || _.includes(qcc, rcf))
        ) {
          return false;
        }
      } else {
        if (!(qcc === "" || _.isNil(qcc) || qcc == rc || qcc == rcf)) {
          return false;
        }
      }
    }
    ///////////////////////////////////////////////////////////////////////////

    //根据过滤器组过滤////////////////////////////////////////////////////////////
    for (let i in query.filters) {
      // 定义块变量及拆分变量
      let col = query.filters[i].column;
      let rc = row[col];
      let rcf = row[`${col}_format`];
      let qfv = query.filters[i].value;
      let qv, qv1, qv2;
      if (qfv instanceof Array) {
        qv1 = qfv[0] === undefined ? undefined : qfv[0];
        qv2 = qfv[1] === undefined ? undefined : qfv[1];
      } else {
        qv = qfv === undefined ? undefined : qfv;
      }
      ///////////////////////////////

      if (query.filters[i].condition == "in" && qfv !== undefined) {
        //过滤字段时需要考虑 format 字段
        if (qfv instanceof Array) {
          if (
            !(_.isEmpty(qfv) || _.includes(qfv, rc) || _.includes(qfv, rcf))
          ) {
            return false;
          }
        } else {
          if (!(qfv === "" || _.isNil(qfv) || qfv == rc || qfv == rcf)) {
            return false;
          }
        }
      } else if (
        query.filters[i].condition == "btw"
      ) {
        if (qfv instanceof Array) {
          rc = Number(rc);
          let nqv1 = Number(qv1);
          let nqv2 = Number(qv2);
          //范围内模式
          //如果该条数据小于起始值并且起始位置不为空，或者大于结束位置并且结束位置不为空，那么过滤该数据
          //范围可以只填写起始或结束
          if ((rc < nqv1 && qv1 != '') || ( rc > nqv2 && qv2 != '')) {
            // console.log(rc, qv1, qv2, "btw");
            //如果不在范围内，那么过滤掉该条数据
            return false;
          }
        }
      } else if (query.filters[i].condition == "includes" && qv !== undefined) {
        //如果是包含
        rc = typeof rc == 'number' ? String(rc) : rc
        rcf = typeof rcf == 'number' ? String(rcf) : rcf

        //如果被查找和查找内容均为字符串，那么全都转化为小写便于忽略大小写查询
        //包含和排除可以排除数组或者对象
        if(typeof rc == 'string' && typeof qv == 'string') {
          rc = rc.toLowerCase()
          rcf = typeof rcf == 'string' ? rcf.toLowerCase() : rcf
          qv = qv.toLowerCase()
        }

        if (!(_.includes(rc, qv) || _.includes(rcf, qv))) {
          //在原字段和格式化字段都没找到那么过滤
          return false;
        }
      } else if (query.filters[i].condition == "excludes" && qv !== undefined) {
        //如果是排除
        rc = typeof rc == 'number' ? String(rc) : rc
        rcf = typeof rcf == 'number' ? String(rcf) : rcf

        //如果被查找和查找内容均为字符串，那么全都转化为小写便于忽略大小写查询
        //包含和排除可以排除数组或者对象
        if(typeof rc == 'string' && typeof qv == 'string') {
          rc = rc.toLowerCase()
          rcf = typeof rcf == 'string' ? rcf.toLowerCase() : rcf
          qv = qv.toLowerCase()
        }

        if (_.includes(rc, qv) || _.includes(rcf, qv)) {
          //在原字段和格式化字段都没找到那么过滤
          return false;
        }
      } else if (query.filters[i].condition == ">" && qv !== undefined) {
        // console.log(rc, qv, rc <= qv, `保留${rc} > ${qv},该条过滤`);
        if (Number(rc) <= Number(qv)) {
          // 小于保留
          return false;
        }
      } else if (query.filters[i].condition == "<" && qv !== undefined) {
        if (Number(rc) >= Number(qv)) {
          // 大于保留
          // console.log(rc, qv, rc >= qv, `保留${rc} < ${qv},该条过滤`);
          return false;
        }
      } else if (query.filters[i].condition == "=" && qv !== undefined) {
        if (rc != qv) {
          // console.log(rc, qv, rc == qv, `保留${rc} = ${qv},该条过滤`);
          return false;
        }
      }
    }

    /////////////////////////////////////////////////////////////////////////////////////

    //如果有全文检索那么进行判断
    if (!(query.fullTextSearch === "" || _.isNil(query.fullTextSearch))) {
      //只搜索  columns 中定义的列的数据
      var rowString = "";
      for (var c in this.tableShowColumns) {
        var column = this.tableShowColumns[c];
        if (!_.isNil(row[column])) {
          rowString += " " + row[column];
        }

        if (!_.isNil(row[column + "_format"])) {
          rowString += " " + row[column + "_format"];
        }
      }

      //如果不包含搜索文本，那么返回 false
      if (rowString.search(search) == -1) {
        return false;
      }
    }

    return true;
  });

  //每次过滤查询均重置到第一页 防止错误发生
  this.currentPage = 1;

  //每次过滤查询重新统计数据长度
  this.total = this.filterRows.length;

  //console.log(data);
  //return rows;
}
