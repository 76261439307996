// import { isEmpty } from "@platform/lib/util";;
import formulaEval from "@platform/components/table/methods/formulaEval";
//对于每个迭代的单元格进行数据补全，如果数据已经补全则不再次运算提高效率
var formatIterator = function (row, column) {
  let cf = `${column}_format`
  if(row[cf] == undefined || this.plugins.useTree) { //如果是树状结构组件特殊处理格式化的数据不缓存
    if(this.tablePlugins?.format?.[column]) {
      row[cf] = this.tablePlugins.format[column](row[column], row)
      return row[cf]
    } else {
      return row[column]
    }
  } else {
    return row[cf]
  }
}

var formulaIterator = function (row, column) {
  if(row[column] == undefined) {
    if(this.tablePlugins?.formula?.[column]) {
      let mv
      try {
        mv = formulaEval(this.tablePlugins.formula[column], row);
        mv = mv == -Infinity || mv == Infinity || mv == NaN ? 0 : mv
      } catch {}
      row[column] = mv
    }
  }

  return row[column]
}

var cellIterator = function (row, column) {
  // this.formulaIterator(row, column)
  return this.formatIterator(row, column)
}

export default {
  cellIterator,
  formatIterator,
  formulaIterator
}
