import Vue from "vue";
import "umy-ui/lib/theme-chalk/index.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/views/facebook/style/index.scss";
//加载第三方fontawesome图标字体
import "font-awesome/css/font-awesome.css";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets

import Element from "element-ui";

import $moment from "moment";
import dayjs from "dayjs";

import { createPinia, PiniaVuePlugin } from "pinia";

// import "@/styles/index.scss"; // global css
import "@/styles/seaTheme.scss"; // global css

import App from "./App.vue";

import router from "./router";

import { get_user } from "@src/utils/auth";

import "./permission"; // permission control

//平台要用到的相关组件
import PlatformDateRangePicker from "@platform/components/daterangepicker/index.vue";
import PlatformSelect from "@platform/components/select/index.vue";
import PlatformTable from "@platform/components/table/index.vue";
import PlatformPaginationTable from "@platform/components/table/indexPagination.vue";

import PlatformLoadTable from "@platform/components/table/indexLoad.vue";
import PlatformCohortTable from "@platform/components/table/indexLoadCohort.vue";
import PlatformAppsflyerTable from "@platform/components/table/indexLoadAppsflyer.vue";
import PlatformEffectTable from "@platform/components/table/indexEffect.vue";

import PlatformXeditable from "@platform/components/xeditable/index.vue";
import PlatformPanel from "@platform/components/panel/index.vue";
import PlatformFormulaInput from "@platform/components/formula-input/index.vue";
import "@/icons"; // icon
//注册 PlatformValidator 插件 基于 vue-form
import PlatformValidator from "@platform/plugins/validator";

Vue.component("el-date-range-picker", PlatformDateRangePicker);
Vue.component("platform-select", PlatformSelect);
Vue.component("platform-table", PlatformTable);
Vue.component("platform-pagination-table", PlatformPaginationTable);

Vue.component("platform-load-table", PlatformLoadTable);
Vue.component("platform-cohort-table", PlatformCohortTable);
Vue.component("platform-effect-table", PlatformEffectTable);
Vue.component("platform-appsflyer-table", PlatformAppsflyerTable);

Vue.component("platform-xeditable", PlatformXeditable);
Vue.component("platform-panel", PlatformPanel);
Vue.component("platform-formula-input", PlatformFormulaInput);
// 老框架的请求接口逻辑处理 start
import PlatformPluginsHttp from "@platform/plugins/http";
import PlatformPluginsApi from "@platform/plugins/api";
import PlatformPluginsConfig from "@platform/plugins/config";
import PlatformPermission from "@platform/plugins/permission";
import PlatformConst from "@platform/plugins/const";
import {
  positive_integer,
  positive_float,
  format_number,
} from "@src/utils/validator";
Vue.use(PlatformPluginsHttp);
Vue.use(PlatformPluginsApi);
Vue.use(PlatformPluginsConfig);
Vue.use(PlatformPermission);
Vue.use(PlatformConst);
Vue.use(PlatformValidator, {});
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
// 老框架的请求接口逻辑处理 end
import placeholder from "@/assets/img/placeholder.png";

Vue.config.productionTip = false; // 阻止vue启动生产消息

let user = JSON.parse(get_user() || "{}");

Vue.prototype.$moment = $moment;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$user = user;
window.$user = user;

Vue.prototype.$userMoment = function (date = undefined, keepLocalTime = false) {
  return this.$moment(date).utcOffset(Number(user.timezone), keepLocalTime);
};

//确认框相关
Vue.use({
  install: (Vue) => {
    Vue.prototype.toggle_warning = function (_msg) {
      let _default_params = {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "el-change-cancel",
        confirmButtonClass: "el-change-sure",
      };
      let _params = Object.assign({}, _default_params, _msg.params || {});
      return this.$confirm(_msg.main, "提示", _params);
    };
    /***
   @description:适用于VueForm验证错误时，当页面长度大于一屏时。可以定位到具体的错误表单处
   @author:johnny
   @param:_error_list<Array<String>>
  */
    Vue.prototype.positionErrorMsg = function (_error_list) {
      // eslint-disable-next-line no-undef
      var error_keys = _.keys(_error_list);
      var g = error_keys.map((x) => {
        var l = document.querySelector("*[name='" + x + "']");
        return {
          bounding: l.getBoundingClientRect().y,
          c: x,
        };
      });
      if (g.length <= 0) return;
      // eslint-disable-next-line no-undef
      var final_name = _.orderBy(g, "bounding")[0].c;
      var error_element = document.querySelector(
        "*[name='" + final_name + "']"
      );
      var bounding = error_element.getBoundingClientRect();
      document.querySelector(".app-main").scrollTop =
        document.querySelector(".app-main").scrollTop + bounding.y - 60 - 34;
    };
  },
});

Vue.directive("positive-float", positive_float);
Vue.directive("format-number", format_number);
Vue.directive("positive-integer", positive_integer);
Vue.directive("img-error", {
  inserted: function (el, binding) {
    el.onerror = function () {
      el.src = binding.value || placeholder;
    };
    if (!el.src) {
      el.src = binding.value || placeholder;
    }
  },
});
async function loadUserTheme(inmad) {
  let themeFile;
  if (inmad) {
    themeFile = await import("@/styles/seaTheme.scss");
  } else {
    themeFile = await import("@/styles/index.scss");
  }
  return themeFile;
}
Vue.use(Element, {
  size: "small",
});
new Vue({
  router,
  pinia,
  async created() {
    // 假设在此处从后端或本地存储获取用户选择的主题
    const inmad = user.inmad || user.user_type == 1; // 示例：根据用户信息设置主题
    await loadUserTheme(inmad);
  },
  render: (h) => h(App),
}).$mount("#app");
