import request from "@/utils/request";
// api/ads/rule/list
export function httpRuleList(params) {
  return request({
    url: "/api/ads/rule/list",
    method: "get",
    params: params,
  });
}
//  ruleDel
export function httpRuleDel(params) {
  return request({
    url: "/api/ads/rule/del",
    method: "get",
    params: params,
  });
}
//  ruleCreate
export function httpRuleCreate(params) {
  return request({
    url: "/api/ads/rule/create",
    method: "post",
    data: params,
  });
}
//  ruleUpdate
export function httpRuleUpdate(params) {
  return request({
    url: "/api/ads/rule/update",
    method: "post",
    data: params,
  });
}
//  searchReport3rd
export function httpSearchReport3rd(params) {
  return request({
    url: "/api/open-api/report/3rd/search",
    method: "post",
    data: params,
  });
}
//  rawDataCustomizeTiktokReport
export function httpRawDataCustomizeTiktokReport(params) {
  return request({
    url: "/api/report/customize/tiktok/report",
    method: "post",
    data: params,
  });
}
//  rawDataCustomizeFacebookReport
export function httpRawDataCustomizeFacebookReport(params) {
  return request({
    url: "/api/report/customize/facebook/report",
    method: "post",
    data: params,
  });
}
//  rawDataCustomizeGoogleReport
export function httpRawDataCustomizeGoogleReport(params) {
  return request({
    url: "/api/report/customize/google/report",
    method: "post",
    data: params,
  });
}
//
export function httpRawDataCustomizeTwitterReport(params) {
  return request({
    url: "/api/report/customize/twitter/report",
    method: "post",
    data: params,
  });
}
// rawDataCustomizeSnapchatReport
export function httpRawDataCustomizeSnapchatReport(params) {
  return request({
    url: "/api/report/customize/snapchat/report",
    method: "post",
    data: params,
  });
}
//  rawDataCustomizeUnityReport
export function httpRawDataCustomizeUnityReport(params) {
  return request({
    url: "/api/report/customize/unity/report",
    method: "post",
    data: params,
  });
}
//  rawDatacustomizeAppleSearchReport
export function httpRawDatacustomizeAppleSearchReport(params) {
  return request({
    url: "/api/report/customize/appleSearch/report",
    method: "post",
    data: params,
  });
}

export function httpRawDatacustomizeIronsourceReport(params) {
  return request({
    url: "api/report/customize/ironsource/report",
    method: "post",
    data: params,
  });
}
//  rawDatacustomizeAllPlatformsReport
export function httpRawDatacustomizeAllPlatformsReport(params) {
  return request({
    url: "/api/report/customize/allPlatforms/report",
    method: "post",
    data: params,
  });
}
// getAdsMaterialChart
export function httpGetAdsMaterialChart(params) {
  return request({
    url: "/api/report/Material/getAdsMaterialChart",
    method: "post",
    data: params,
  });
}
// getReportCountry
export function httpGetReportCountry(params) {
  return request({
    url: "/api/report/material/getReportCountry",
    method: "post",
    data: params,
  });
}
//  getAdsMaterialInfo
export function httpGetAdsMaterialInfo(params) {
  return request({
    url: "/api/report/material/getAdsMaterialInfo",
    method: "post",
    data: params,
  });
}
//  tiktokVideoSecondAnalysis
export function httpTiktokVideoSecondAnalysis(params) {
  return request({
    url: "/api/report/aggregation/tiktok/video/second/analysis",
    method: "post",
    data: params,
  });
}
//  tiktokOptimizationLog
export function httpTiktokOptimizationLog(params) {
  return request({
    url: "/api/ads/tiktok/optimization/log",
    method: "post",
    data: params,
  });
}
// campaignList
export function httpCampaignList(params) {
  return request({
    url: "/api/platform/campaignPlatform/campaignList",
    method: "post",
    data: params,
  });
}
// getAdSetList
export function httpGetAdSetList(params) {
  return request({
    url: "/api/platform/adSetPlatform/adsetList",
    method: "post",
    data: params,
  });
}
// getAdList
export function httpGetAdList(params) {
  return request({
    url: "/api/platform/adPlatform/adList",
    method: "post",
    data: params,
  });
}
// removeById
export function httpRemoveById(params) {
  return request({
    url: "/api/ads/warning/delete",
    method: "post",
    data: params,
  });
}

// 新接口 创建透视表相关接口

export function httpAddCustomReportRule(params) {
  return request({
    url: "api/report/openapi/3rd/addCustomReportRule",
    method: "post",
    data: params,
  });
}

export function httpGetCustomReportRule(params) {
  return request({
    url: "api/report/openapi/3rd/getCustomReportRule",
    method: "get",
    params,
  });
}

export function httpDeleteCustomReportRule(params) {
  return request({
    url: "api/report/openapi/3rd/deleteCustomReportRule",
    method: "post",
    data: params,
  });
}

export function httpUpdateCustomReportRule(params) {
  return request({
    url: "api/report/openapi/3rd/updateCustomReportRule",
    method: "post",
    data: params,
  });
}

export function httpDoCustomReportFactor(params) {
  return request({
    url: "api/report/openapi/3rd/doCustomReportFactor",
    method: "post",
    data: params,
  });
}

export function httpReportRuleFactory(params) {
  return request({
    url: "api/report/openapi/3rd/reportRuleFactory",
    method: "post",
    data: params,
  });
}

export function httpGetCustomReportFactor(params) {
  return request({
    url: "api/report/openapi/3rd/getCustomReportFactor",
    method: "get",
    params,
  });
}

// 新增需求接口

export function httpTiktokCohortAddRule(params) {
  return request({
    url: "/api/ads/tiktok/TiktokCohort/addRule",
    method: "post",
    data: params,
  });
}
export function httpTiktokCohortUpdateRule(params) {
  return request({
    url: "/api/ads/tiktok/TiktokCohort/updateRule",
    method: "post",
    data: params,
  });
}
export function httpTiktokCohortRuleList(params) {
  return request({
    url: "/api/ads/tiktok/TiktokCohort/ruleList",
    method: "post",
    data: params,
  });
}
export function httpTiktokCohortCohortAccountGroupList(params) {
  return request({
    url: "api/ads/tiktok/TiktokCohort/cohortAccountGroupList",
    method: "post",
    data: params,
  });
}
export function httpTiktokCohortCohortList(params) {
  return request({
    url: "api/ads/tiktok/TiktokCohort/cohortList",
    method: "post",
    data: params,
  });
}

export function httpTiktokCohortCohortHashList(params) {
  return request({
    url: "api/ads/tiktok/TiktokCohort/cohortHashList",
    method: "post",
    data: params,
  });
}

export function httpReportMaterialReportList(params) {
  return request({
    url: "api/ads/report/MaterialReport/list",
    method: "post",
    data: params,
  });
}

export function httpReportMaterialReportPlatformList(params) {
  return request({
    url: "api/ads/report/MaterialReport/platformList",
    method: "post",
    data: params,
  });
}

export function httpTiktokCohortRulDel(params) {
  return request({
    url: "api/ads/tiktok/TiktokCohort/rulDel",
    method: "get",
    params,
  });
}

export function httpPlatformList(params) {
  return request({
    url: "api/report/openapi/3rd/platformList",
    method: "get",
    params,
  });
}


export function httpSinglePlateNewLog(params) {
  return request({
    url: "api/report/account/fbLog",
    method: "post",
    data: params,
  });
}

export function httpShopifyGetTotalList(params){
  return request({
    url:'/api/ads/report/Shopify/getTotalList',
    method:'post',
    data:params
  })
}

export function httpShopifyTemplate(params){
  return request({
    url:"api/ads/report/Shopify/getTemplate",
    method:'get',
    params
  })
}

export function httpUploadTemplate(params){
  return request({
    url:'/api/ads/report/Shopify/uploadTemplate',
    method:'post',
    data:params
  })
}


export function httpGetShopifySkuList(params){
  return request({
    url:'api/ads/report/Shopify/getSkuList',
    method:'get',
    params:params
  })
}

export function httpShopifySkuEdit(params){
  return request({
    url:'api/ads/report/Shopify/editSku',
    method:'post',
    data:params
  })
}

export  function httpShopifyRefreshList(params) {
  return request({
    url:'/api/ads/report/Shopify/refreshList',
    method:'post',
    data:params
  })
}

export function httpShopifyCheckList(params){
  return request({
    url:'/api/ads/report/Shopify/checkList',
    method:'post',
    data:params
  })
}


export function httpGetShopifyOrderList(params){
  return request({
    url:'/api/ads/report/Shopify/getOrderlList',
    method:'post',
    data:params
  })
}
