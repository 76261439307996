import _ from "lodash";
import formulaEval from "@platform/components/table/methods/formulaEval";
import { isEmpty } from "@platform/lib/util";
import chillout from "chillout"

// chillout.forEach(this.rows, function(value) {
//   console.log(value);
// }).then(function() {
//   console.log('done');
// });

export default function (place) {
  // this.rows 数据驱动部分先循环 this.rows
  //为了提高计算性能只对显示的字段进行公式计算，watch tableShowColumns 改变时也会计算
  let showFormulaColumns = _.intersection(this.tableShowColumns, _.keys(this.tablePlugins.formula))
  // console.time(place+'.event.formulaEval')
  _.forEach(this.rows, row => {
    nestFormula(row, this.tablePlugins.formula);
  })
  function nestFormula(row, tab) {
    _.forEach(showFormulaColumns, column => {
      if(isEmpty(row[column]) || row[column] == 'All') { //All是特定对于分组进行判断 collection.group
        let mv = null
        let formula = tab[column]
        try {
          mv = formulaEval(formula, row);
          mv = mv == -Infinity || mv == Infinity || mv == NaN ? null : mv
        } catch {}
        row[column] = mv
      }
    })
    if (row['children']) {
      _.forEach(row['children'], rows => {
        nestFormula(rows, tab)
      })
    }
  }
  // console.timeEnd(place+'.event.formulaEval')
}

// 异步高性非阻塞，运算计算列 配合行渲染迭代器 性能大致提高30-50%
// export default function (place) {
//   // this.rows 数据驱动部分先循环 this.rows
//   //为了提高计算性能只对显示的字段进行公式计算，watch tableShowColumns 改变时也会计算
//   let showFormulaColumns = _.intersection(this.tableShowColumns, _.keys(this.tablePlugins.formula))
//   console.time(place+'.event.formulaEval')
//   chillout.forEach(this.rows, row => {
//     //对于有formula插件的列，将会进行公式运算
//     chillout.forEach(showFormulaColumns, column => {
//       if(isEmpty(row[column])) {
//         let mv
//         let formula = this.tablePlugins.formula[column]
//         try {
//           mv = formulaEval(formula, row);
//           mv = mv == -Infinity || mv == Infinity || mv == NaN ? 0 : mv
//         } catch {}
//         row[column] = mv
//       }
//     })
//   }).then(() => {
//     setTimeout(() => {
//       // this.rows.splice(0,0)
//       //对全部计算列进行计算
//       // this.showFormulaColumnsEval('columns')
//       //只触发渲染数据提高性能
//       this.filterRows.splice(0,0)
//       console.log('done')
//     }, 0)
//   })
//   console.timeEnd(place+'.event.formulaEval')
// }
