<template>
<div>
    <el-date-picker
      v-model="start_date"
      type="date"
      :size="size"
      :format="format"
      :value-format="valueFormat"
      :disabled="startDisabled"
      :placeholder="startPlaceholder"
      :picker-options="startPickerOptions"
      :required="required"
      >
    </el-date-picker>
    <el-date-picker
      style="margin:5px"
      v-model="end_date"
      type="date"
      :size="size"
      :format="format"
      :value-format="valueFormat"
      :disabled="endDisabled"
      :placeholder="endPlaceholder"
      :picker-options="endPickerOptions"
      :required="required"
      >
    </el-date-picker>
</div>
</template>

<script>
export default {
    props: {
        'value' : {
            type: Array,
            default: [null, null]
        },
        'start-disabled' : {
            required: false,
            type: Boolean,
            default: false
        },
        'end-disabled' : {
            required: false,
            type: Boolean,
            default: false
        },
        'start-readonly' : {
            required: false,
            type: Boolean,
            default: false
        },
        'end-readonly' : {
            required: false,
            type: Boolean,
            default: false
        },
        'start-placeholder' : {
            required: false,
            type: [String, Number],
        },
        'end-placeholder' : {
            required: false,
            type: [String, Number],
        },
        'start-picker-options' : {
            required: false,
            type: Object,
        },
        'end-picker-options' : {
            required: false,
            type: Object,
        },
        size : {
            required: false,
            type: String,
	        default: ''
	    },
        'value-format' : {
        	required: false,
            type: [String, Number],
	        default: 'yyyy-MM-dd'
        },
        format : {
            required: false,
            type: String,
	        default: 'yyyy-MM-dd'
	    },
        editable : {
            required: false,
            type: Boolean,
	        default: true
	    },
        clearable : {
            required: false,
            type: Boolean,
	        default: true
	    },
	    required : {
            required: false,
            type: Boolean,
            default: false
        }
    },
    
    data: function() {
        return {
            "start_date": this.value[0],
            "end_date": this.value[1]
        }
    },
    
    watch: {
        value : function() {
            this.start_date = this.value[0];
            this.end_date = this.value[1];
        },
        start_date: function() {
            this.$emit('input', [this.start_date, this.end_date])
        },
        end_date: function() {
            this.$emit('input', [this.start_date, this.end_date])
        }
    }
}
</script>