import router from "./router";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false }); // NProgress Configuration
import { get_authority_routes } from "@src/utils/auth";



const whiteList = [
  "/login",
  "/404",
  "/401",
  "/auth-redirect",
  "/contact/wechatInfo",
  "/site/index",
  "/site/index",
  "/adLot",
  '/afCustomView',
  '/silentSignIn',
  '/chargpt/uploadImage',
  '/chatgpt/uploadImage',
]; // no redirect whitelist

const needCheckRoutes = ["/folder-manage", "/material-authority"];

router.beforeEach(async (to, from, next) => {
  let authorityRoutes = get_authority_routes() || [];
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  if (hasToken) {

    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (needCheckRoutes.includes(to.path)) {
        if (authorityRoutes.includes(to.path)) {
          next();
          NProgress.done();
        } else {
          next("/401");
          NProgress.done();
        }
      } else {
        next();
        NProgress.done();
      }
    }
  } else {
    if (to.path === "/login") {
      next();
    } else if (whiteList.indexOf(to.path) !== -1) {

      // other pages that do not have permission to access are redirected to the login page.
      next();
    } else {
      const queryString = new URLSearchParams(to.query).toString()
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}&${queryString}`)
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
