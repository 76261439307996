import { isEmpty } from "@platform/lib/util";

export default function () {
  var columnData = {};
  if (this.filterRows.length && this.showSummary) {
    this.showSummary = true;
  } else {
    this.showSummary = false;
  }

  for(var item of this.filterRows) {
    _.forEach(item, function (cell, column) {
      if (column.indexOf("_format") == -1) {
        if (!_.isArray(columnData[column])) {
          columnData[column] = [];
        }
        columnData[column].push(cell);
      }
    });
  };

  //只要有某列数据使用了 summary 插件，那么自动显示汇总行
  this.showSummary = !_.isEmpty(this.tablePlugins.summary) ? true : false;

  _.forEach(columnData, (item, column) => {

    this.summaryData[column] = null

    //如果定义了该列的汇总计算方法那么执行，否则默认返回 null 格式化不在此处
    if (this.tablePlugins.summary && this.tablePlugins.summary[column]) {
      this.summaryData[column] = this.tablePlugins.summary[column](
        item,
        columnData
        );
      }
  });
  //console.log(this.summaryData);
}
