
import ComponentGroupColumns from './columns.vue'
import {httpUseCustom} from "@/api/common";
export default {
  components: {
    'component-group-columns': ComponentGroupColumns
  },
  watch: {
    dataRows(data) {
      this.groupData()
    },
    "group.columns": {
      handler: function (groupColumns, oldGroupColumns) {

        //所有group字段都是fixed
        _.forEach(oldGroupColumns, (c) => {
          this.$set(this.tableColumnProps[c], 'fixed', false)
          this.$set(this.tablePlugins.fixed, c, false)
        })

        _.forEach(groupColumns, (c) => {
          this.$set(this.tableColumnProps[c], 'fixed', true)
          this.$set(this.tablePlugins.fixed, c, true)
        })

        if (!_.isEqual(groupColumns, oldGroupColumns)) {
          this.groupData()
          this.showColumnsTrigger()
          this.groupColumnsSave()
        }
      },
      deep: true
    }
  },
  data() {
    return {
      group: {
        columns: [],
        merge: false, //是否只显示顶层合并后的数据
        dialog: false
      }
    }
  },
  mounted() {
    this.groupColumnsLoad()
  },
  methods: {
    groupData() {
      this.$nextTick(() => {
        //处理php生成树结构的情况
        if (this.rows[0]?.xifenCol && this.rows[0]?.xifenCol.length>0) {
          this.group.columns=this.rows[0].xifenCol
        }else{
            this.rows = this.collectionGroup(
              this.dataRows,
              this.group.columns,
              this.tablePlugins?.summary,
              this.tablePlugins.showtableList,
              this.group.merge
            )

            // console.log(JSON.stringify(this.rows))
          }
      })
    },
    groupColumnsLoad() {
      //如果自定义表头开启，并且表格ID存在，同时自定义表头数据未加载的情况下才加载数据
      if (this.tablePlugins?.group && this.id && this.group.columns.length < 1) {
        httpUseCustom({key:'table:group:' + this.id,value:[]}).then((group) => {
          console.log(group, 'groupload')
          if (_.isArray(group.columns) && group.columns.length > 0) {
            this.group.columns = group.columns;
          }else{
            this.$emit('handleGroup',group.columns);
          }
        });
      }
    },
    groupColumnsSave() {
      if (this.tablePlugins?.group && this.id) {
        let group = {};
        group["columns"] = this.group.columns;
        console.log(group, 'groupsave')
        this.$emit('handleGroup',group.columns);
        httpUseCustom({key:'table:group:' + this.id,value:group,type:'update'})
        .then((status) => {
        })
      }
    },
  }
}
