import { Loading } from 'element-ui';

//通过 平台 Vue bus 传递时间控制 v-loading 指令的开关
import PlatformBus from 'platform/bus';

//全局加载类
class Spinner {
  constructor() {
    this.count = {};
    this.instance = {};
  }

  show(opt = { target: "document.body" }) {
    opt.target = '.app-main'
    this.count[opt.target] = this.count[opt.target] || 0;

    
    if (!this.count[opt.target]) { //如果没有 loading 的情况下 开启 loading
      if(opt.target == 'globalLoading') {
        PlatformBus.$root.$emit("bus.globalLoading", true);
      } else {
        this.instance[opt.target] = Loading.service(opt);
      }
    }

    this.count[opt.target]++;
  }

  hide(opt = { target: "document.body" }) {
    opt.target = '.app-main'
    this.count[opt.target] = this.count[opt.target] || 0;
    this.count[opt.target]--;
    if (this.count[opt.target] === 0) {
      if(opt.target == 'globalLoading') {
        PlatformBus.$root.$emit("bus.globalLoading", false);
      } else if(this.instance[opt.target]) {
        this.instance[opt.target].close();
        // this.instance[opt.target] = null;
      }
    }
    
    if (this.count[opt.target] < 0) {
      this.count[opt.target] = 0;
    }
  
  }

  reset(opt = { target: "document.body" }) {
    if(opt.target == 'globalLoading') {
      PlatformBus.$root.$emit("bus.globalLoading", false);
    } else if(this.instance[opt.target]) {
      this.instance[opt.target].close();
    }
    this.count[opt.target] = 0;
   
  }
}


export default {
  install : function (Vue, name) {
    Vue.prototype.$spinner = new Spinner;
  }
}