<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from "vue";
import { useUserStore } from "@/store/pinia/index";
let userStore = useUserStore();


let userName = computed(() => {
  return userStore.user.username;
});
let inmad = computed(() => {
  return userStore.user.inmad;
});

let user_type = computed(() => {
  return userStore.user.user_type;
});


import { removeWatermark, setWaterMark } from "@/utils/watermark";
function handleIsWaterMark() {
  if (userStore.user.company_en == "boke") {
    setWaterMark(userName.value || "", "");
  }
}
function handleTheme() {
  if (inmad.value || user_type.value == 1) {
    location.reload();
  }
}
onMounted(() => {
  nextTick(() => {
    handleIsWaterMark();
  });
});
onUnmounted(() => {
  removeWatermark();
});
watch(
  () => inmad.value,
  () => {
    handleTheme();
  }
);

watch(
  () => userName.value,
  () => {
    if (userStore.user.company_en == "boke") {
      setWaterMark(userName.value || "", "");
    }
    handleIsWaterMark();
  }
);
</script>
<style>
/* 改变进度条的颜色 */
#nprogress .bar {
  background: #58406e; /* 这里的颜色代码可以换成你想要的颜色 */
}
</style>
