import ComponentCustomColumns from "./columns.vue";
import _ from "lodash";
import { httpUseCustom, httpCompanyCustom } from "@/api/common";
import { getUniqueRandomNumber } from "@/utils/util";
import { useSettingsStore } from "@/store/pinia";
export default {
  components: {
    "component-custom-columns": ComponentCustomColumns,
  },
  watch: {
    "custom.columns": {
      handler(customColumns, oldCustomColumns) {
        if (!_.isEqual(customColumns, oldCustomColumns)) {
          if (!customColumns.length) {
            this.custom.columns = this.tableDefalutShowColumns;
          }

          

          this.showColumnsTrigger();
          // this.customColumnsSave()
        }
      },
      // immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      custom: {
        dialog: false,
        columns: [],
        name: "默认表头", //当天头名称，填写头那么为默认头
        type: "user",
        userId: this.$user && this.$user.id,
        headers: {
          user: [{ name: "默认表头", columns: [] }], //用于保存所有头信息，包含多个custom.columns
          company: [],
        },
      },
    };
  },
  mounted() {
    let promises = this.customColumnsLoad();

    if (this.tablePlugins.custom && this.id) {
      //必须有表格id
      //记录最后一次选择的表头
      this.$latestConfig("table:custom:" + this.id, [
        "custom.name",
        "custom.type",
      ]).then(async () => {
        //尽可能减少把异步变成同步，确保已经成功加载用户和公司表头后继续执行
        await Promise.all(promises);
        let customData = this.custom.headers[this.custom.type];

        //查找当前表头名称获取字段
        let findItem = { name: this.custom.name };
        let i = _.findIndex(customData, findItem);

        //如果当前表头在表头组中，并且有配置数据，那么使用配置数据
        // console.log(findItem, _.size(customData[i].columns))
        if (_.size(customData[i]?.columns)) {
          this.$set(this.custom, "columns", customData[i].columns);
          this.$set(this.custom, "userId", customData[i].userId);
        } else {
          //如果没有数据
          let header = _.find(this.custom.headers.user, { name: "默认表头" });
          try {
            this.$set(this.custom, "columns", header.columns || "");
            this.custom.type = "user";
            this.custom.userId = this.$user && this.$user.id;
            this.custom.name = header.name;
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  },
  methods: {
    customColumnsLoad() {
      let promises = [];
      //如果自定义表头开启，并且表格ID存在，同时自定义表头数据未加载的情况下才加载数据
      if (this.tablePlugins.custom && this.id && !this.custom.columns.length) {
        // promises[0] = this.$api('platform.user').custom('table:custom:' + this.id)

        httpUseCustom({ key: "table:custom:" + this.id }).then((custom) => {
          if (_.size(custom)) {
            //如果不是空数组那么使用加载的数据，如果是空那么使用代码中的配置
            this.$set(this.custom.headers, "user", custom);
          }
        });

        promises[1] =
          // this.$api('platform.company').custom('table:custom:' + this.id)
          httpCompanyCustom({ key: "table:custom:" + this.id }).then(
            (custom) => {
              this.$set(this.custom.headers, "company", custom);
            }
          );
      }
      return promises;
    },
    customColumnsSave(name, columns, type = "user") {
      if (this.tablePlugins.custom && this.id) {
        //如果没填写表头那么认为是修改默认表头
        this.custom.name = name || "默认表头";
        this.custom.columns = columns;
        this.custom.type = type;

        //根据传入的名字和表头信息组成 item 以便于更新或新增
        let item = {
          name: this.custom.name,
          columns: this.custom.columns,
          userId: this.$user && this.$user.id,
        };

        let findItem = { name: this.custom.name };
        //如果查找到说明修改已有表头
        let i = _.findIndex(this.custom.headers[type], findItem);
        if (i >= 0) {
          this.custom.headers[type][i].columns = item.columns;
        } else {
          //没有查找到则新增表头
          this.custom.headers[type].push(item);
        }
        if (type == "user") {
          httpUseCustom({
            key: "table:custom:" + this.id,
            value: this.custom.headers[type],
          }).then((status) => {
            let settingsStore = useSettingsStore();
            settingsStore.handleCohortReport(getUniqueRandomNumber(1, 99999));
            this.$emit("handleChangeTag"); 

          });
        } else {
          // 保存表头
          httpCompanyCustom({
            key: "table:custom:" + this.id,
            value: this.custom.headers[type],
          }).then((status) => {
            let settingsStore = useSettingsStore();
            settingsStore.handleCohortReport(getUniqueRandomNumber(1, 99999));
            this.$emit("handleChangeTag"); 
          });
        }
      }
     
    },

    //删除表头
    customHeaderRemove(name, type = "user") {
      if (this.tablePlugins.custom && this.id) {
        //根据当前表头名称删除表头
        let findItem = { name: name };

        let i = _.findIndex(this.custom.headers[type], findItem);

        //如果删除的是用户默认表头那么重置而非删除
        if (name == "默认表头" && type == "user") {
          this.custom.headers[type][i].columns = [];
        } else {
          this.$delete(this.custom.headers[type], i);
        }

        //如果删除的是当前在使用的表头那么跳转到用户级别默认表头
        if (name == this.custom.name && type == this.custom.type) {
          //删除后使用用户默认表头填充
          let header = _.find(this.custom.headers.user, { name: "默认表头" });
          this.custom.name = header?.name ?? "默认表头";
          this.custom.type = "user";
          this.custom.columns = header?.columns ?? [];
        }

        if (type == "user") {
          httpUseCustom({
            key: "table:custom:" + this.id,
            value: this.custom.headers[type],
          }).then((status) => {});
        } else if (type == "company") {
          // 保存表头
          httpCompanyCustom({
            key: "table:custom:" + this.id,
            value: this.custom.headers[type],
          }).then((status) => {});
        }
      }
    },
  },
};
