import _, { find } from "lodash";
import PlatformComponentConfig from "./select.vue";
import { httpUseCustom } from "@/api/common";

import { getUniqueRandomNumber } from "@/utils/util";
import { useSettingsStore } from "@/store/pinia";

export default {
  install: function (Vue, name) {
    Vue.mixin({
      components: {
        "platform-component-config": PlatformComponentConfig,
      },
      data: function () {
        return {
          platform_config_values: {},
          platform_component_config: {
            id: "",
            watch: [], //监听的配置变量
            name: "默认配置",
            value: {},
            initValue: {},
            configs: [{ name: "默认配置", value: {}, default: true }],
          },
        };
      },
      watch: {
        "platform_component_config.name": {
          handler(name) {
            if (!_.isEmpty(this.platform_component_config.configs)) {
              let save = true; //默认保存每次重新选择的表头
              _.forEach(this.platform_component_config.configs, (config) => {
                //如果选择的表头本身就是默认配置 那么不保存
                if (config.name == name && config.default) {
                  save = false;
                }

                if (config.name == name) {
                  config.default = true;
                } else {
                  config.default = false;
                }
              });

              //根据当前配置名称设置配置
              this.$configSet(name);

              // 保存默认
              if (save && this.platform_component_config.id) {
                httpUseCustom({
                  key: this.platform_component_config.id,
                  value: this.platform_component_config.configs,
                }).then((status) => {
                  let settingsStore = useSettingsStore();
                  settingsStore.handleAnginReport(
                    getUniqueRandomNumber(1, 99999)
                  );
                });
              }
            }
          },
        },
      },
      methods: {
        $latestConfig(id, watchConfig, perfix = "config:latest") {
          id = perfix + ":" + id;

          return httpUseCustom({ key: id }).then((sc) => {
            let wc = {};

            //基于监听key对保存的老配置进行清理
            sc = _.pick(sc, watchConfig);

            for (let k of watchConfig) {
              wc[k] = _.get(this, k);
            }

            //加载进来的数据合并到监听数据上
            let config = _.merge(wc, sc);

            //载入配置
            for (let k in config) {
              this.$set(this.platform_config_values, k, config[k]);
              _.set(this, k, config[k]);

              this.$watch(
                k,
                function (newVal, oldVal) {
                  this.$set(this.platform_config_values, k, newVal);
                },
                {
                  deep: true,
                }
              );
            }

            //监听platform_config_values用于保存，同时添加了方震荡
            this.$watch(
              "platform_config_values",
              _.debounce(function (platform_config_values) {
                httpUseCustom({ key: id, value: platform_config_values }).then(
                  (sc) => {
                    let settingsStore = useSettingsStore();
                    settingsStore.handleAnginReport(
                      getUniqueRandomNumber(1, 99999)
                    );
                  }
                );
              }, 500),
              {
                deep: true,
              }
            );

            return this.platform_config_values;
          });
        },

        //读取config中的一个配置，并绑定到外部变量
        $config(id, watch, perfix = "config") {
          //将组件配置ID传给组件
          this.platform_component_config.id = perfix + ":" + id;
          console.log("config");
          this.platform_component_config.watch = watch;

          //将页面中的默认配置放到初始化initValue中

          let wv = {};
          //根据监听配置讲对应值取出等待被已保存的配置覆盖
          for (let k of watch) {
            wv[k] = _.get(this, k);
          }

          this.platform_component_config.initValue = wv;
          ////////////////

          return httpUseCustom({
            key: this.platform_component_config.id,
          }).then((sc) => {
            //默认使用配置名称
            let findName = this.platform_component_config.name;

            if (!_.isEmpty(sc)) {
              //如果组件配置不为空
              //设置组件配置组
              this.$set(this.platform_component_config, "configs", sc);

              //查找配置组中是否有默认配置，如果有默认配置，根据默认配置设置数据
              _.forEach(sc, (config) => {
                if (config.default) {
                  findName = config.name;
                }
              });
            }

            this.$configSet(findName);

            return this.platform_component_config.value;
          });
        },

        $configSet(name) {
          let sc = this.platform_component_config.configs;
          let watch = this.platform_component_config.watch;

          //查找当前配置数据
          let findItem = { name: name };
          let i = _.findIndex(sc, findItem);

          //如果当前配置有数据，那么使用配置数据
          if (!_.isEmpty(sc[i]?.value)) {
            this.$set(this.platform_component_config, "value", sc[i].value);
            this.$set(this.platform_component_config, "name", name);
          }

          let wc = {};

          //基于监听key对保存的老配置进行清理
          let vc = _.pick(this.platform_component_config.value, watch);

          let wv = {};
          //根据监听配置讲对应值取出等待被已保存的配置覆盖
          for (let k of watch) {
            wv[k] = _.get(this, k);
          }

          //加载进来的数据合并到监听数据上
          let config = _.merge(wc, vc);

          //载入配置
          for (let k in config) {
            _.set(this, k, config[k]);
          }
        },

        //存储当前name + value到configs
        $configSave(name) {
          if (
            this.platform_component_config.id &&
            this.platform_component_config.watch
          ) {
            let value = {};

            //获取监听变量的值保存
            for (let k of this.platform_component_config.watch) {
              value[k] = _.get(this, k);
            }

            //如果没填写表头那么认为是修改默认配置
            this.platform_component_config.name = name || "默认配置";
            this.platform_component_config.value = value;

            //根据传入的名字和表头信息组成 item 以便于更新或新增
            let item = {
              name: this.platform_component_config.name,
              value: this.platform_component_config.value,
            };

            let findItem = { name: this.platform_component_config.name };

            //如果查找到说明修改已有表头
            let i = _.findIndex(
              this.platform_component_config.configs,
              findItem
            );
            if (i >= 0) {
              this.platform_component_config.configs[i].value = item.value;
              // this.platform_component_config.configs.splice(_.findIndex(this.platform_component_config.configs, findItem), 1, item);
            } else {
              //没有查找到则新增表头
              this.platform_component_config.configs.push(item);
            }

            // 保存表头

            httpUseCustom({
              key: this.platform_component_config.id,
              value: this.platform_component_config.configs,
              type: "update",
            }).then((status) => {});
          }
        },

        //删除configs中的一个配置
        $configRemove(name) {
          //组件配置 id 存在才可以删除
          if (this.platform_component_config.id) {
            //如果删除的是默认配置，那么相当于初始化配置
            if (name == "默认配置") {
              let i = _.findIndex(this.platform_component_config.configs, {
                name: "默认配置",
              });
              this.$set(this.platform_component_config.configs, i, {
                name: "默认配置",
                value: this.platform_component_config.initValue,
              });
            } else {
              //根据当前表头名称删除表头
              let findItem = { name: name };
              let i = _.findIndex(
                this.platform_component_config.configs,
                findItem
              );
              this.$delete(this.platform_component_config.configs, i);
            }

            //如果删除的配置为当前配置那么讲当前配置指向 默认配置
            if (this.platform_component_config.name == name) {
              this.$configSet("默认配置");
            }

            //保存删除后的表头数据
            httpUseCustom({
              key: this.platform_component_config.id,
              value: this.platform_component_config.configs,
              type: "update",
            }).then((status) => {
              let settingsStore = useSettingsStore();
              settingsStore.handleAnginReport(getUniqueRandomNumber(1, 99999));
            });
          }
        },
      },
    });
  },
};
