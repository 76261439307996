
export default {
  watch: {
    "clientHeight" : {
      handler (n, o) {
        
          let maxHeight = this.options?.maxHeight ?? -185;
          if(maxHeight < 0) {
            this.$set(this.tableOptions, "maxHeight",  this.clientHeight + maxHeight);
          }
      },
      immediate: true,
      deep: true,
    }
  },

  data() {
    return {
      clientHeight: document.documentElement.clientHeight
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.clientHeight = document.documentElement.clientHeight
    }
  }
}

