import Vue from 'vue';
import VueForm from 'vue-form';

let _inputClasses = {
    valid: 'form-control-success',
    invalid: 'form-control-danger'
}

export default {
    install : function (Vue, {inputClasses = _inputClasses, validators = {}} = {}) {
    
        validators.server = function(value, a, vnode) {
            // debugger;
            let model = vnode.data.attrs.server;
            let scenario = vnode.data.attrs.scenario || null;
    
            if(model) {
                let field = vnode.data.attrs.name;
                return vnode.context.$api(model).backendFieldValidate({[field]:value}, scenario).then(function(result){
                    if(result[field]&&result[field].length > 0){
                        Vue.set(vnode.context.$data.server_validation_error, field, result[field][0]);
                        return false;
                    }else{
                        return true;
                    }
                })
            }
    
            return true;
        };
        validators.elserver = function(value, a, vnode) {
            // debugger;
            // var vnode=pnode.elm.children[0];
            let valiTime=null;
            let model = vnode.data.attrs.elserver;
            let scenario = vnode.data.attrs.scenario || null;
            
            if(model) {
                let field = vnode.data.attrs.elname;
                return vnode.context.$api(model).backendFieldValidate({[field]:value}, scenario).then(function(result){
                    if(result[field]&&result[field].length > 0){
                        Vue.set(vnode.context.$data.server_validation_error, field, result[field][0]);
                        return false;
                    }else{
                        return true;
                    }
                })
            }
    
            return true;
        };
        validators["invalid-string"] = function(value, attrValue, vnode){
            var containSpecial = RegExp(/[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/);
            return !containSpecial.test(value)
        }
        
        validators.matches = function(value, attrValue) {
            if (!attrValue) {
                return true;
            }
            return value === attrValue;
        }
        
        //注册 vue-form 插件
        Vue.use(VueForm, {
            inputClasses: inputClasses,
            validators: validators
        });
        
        Vue.mixin({
            data: function() {
                return {
                    server_validation_error: {
                    }
                }; 
            }
        });
    }
}