<template>
  <div class="sidebar-logo-container">
    <router-link key="expand" class="sidebar-logo-link" to="/">
      <div class="sidebar-logo"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {},
  data() {
    return {
      title: "Vue Element Admin",
    };
  },
};
</script>

