import { render, staticRenderFns } from "./indexLoad.vue?vue&type=template&id=824d1256&"
import script from "./indexLoad.vue?vue&type=script&lang=js&"
export * from "./indexLoad.vue?vue&type=script&lang=js&"
import style0 from "./indexLoad.vue?vue&type=style&index=0&id=824d1256&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports