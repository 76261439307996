import _ from "lodash";
import {Parser} from "acorn";
import { full } from "acorn-walk";
import numeral from "numeral";
import formulaEval from "@platform/components/table/methods/formulaEval";
import { isEmpty } from "@platform/lib/util";
import fastCopy from "fast-copy";

export default function () {

  let plugins = fastCopy(this.plugins);

  //字段配置插件类型
  let types = [
    "menu",
    "switch",
    "filter",
    "format",
    "hidden",
    "edit",
    "summary",
    "summaryFormula",
    "summaryFormat",
    // "groupSummary",
    // "groupFormula",
    "fixed",
    "dataType", //类型(number/attribute/currency/percentage)
    "formula", //数学公式(基于列数据)
    "default" //缺省值
  ];

  plugins = _.assign(
    {
      //表格默认参数
      export: false, //导出数据默认值 false
      search: true, //搜索模块默认值 true
      custom: true, //可配置列默认值 true
      group: false, //分组统计列表默认值 false
      filters: true, //可保存组合过滤器默认值 true
      summarys: false, //是否进行汇总 默认值 false
      uniqueKey: null
    },
    plugins
  );

  this.tablePlugins = plugins;

  //循环 字段属性中的 插件配置，如果在字段属性中配置了，那么覆盖plugins中配置的
  //columns 中配置的 插件优先级更高
  _.forEach(types, (type) => {
    this.$set(this.tablePlugins, type, this.tablePlugins[type] || {})
    _.forEach(this.tableColumnProps, (props, column) => {
      if (!isEmpty(props[type])) {
        this.$set(this.tablePlugins[type], column, props[type])
      }
    });
  });

  //以下是对 tablePlugins 的具体操作(不涉及rows数据触发部分)//////////////////////////////////////////////////

  //对于有format插件的列，创建format数据列，并将该format列隐藏
  //format列主要用于进行数据筛选，而原数据列会根据 slot 的方式通过format渲染，确保该列能够正常排序
  if (this.tablePlugins.edit) {
    _.forEach(this.tablePlugins.edit, (edit, column) => {
      edit.afterSave = edit.afterSave ? edit.afterSave : this.afterSave;
    });
  }

  //汇总公式
  let summaryFormula = function(c, ac, m) {
    let rowsLength = c.length
    let sumColKeys = []

    //AST解析得到所有变量
    full(Parser.parse(m, {ecmaVersion: 5}), node => {
      if(node.type == 'Identifier') {
        sumColKeys.push(node.name)
      }
    })

    let sumCols = {}
    _.forEach(sumColKeys, sc => {
      //将字段转化为数字后累加
      sumCols[sc] = _.sumBy(ac[sc], item => Number(item))
    })

    //将合计后的数值转给公式，得到汇总计算返回
    let sv = null
    try {
      sv = formulaEval(m, sumCols, rowsLength);
      sv = sv == -Infinity || sv == Infinity || sv == NaN ? null : sv
    } catch {}

    return sv
  }

  //汇总公式
  if (this.tablePlugins.summaryFormula) {
    _.forEach(this.tablePlugins.summaryFormula, (m, column) => {
      this.tablePlugins.summary[column] = this.tablePlugins.summary[column] ?? function(c, ac)  {
        return summaryFormula(c, ac, m)
      }

      //group默认使用汇总公式
      // this.tablePlugins.groupSummary[column] = this.tablePlugins.summary[column]
    });
  }

  //分组子汇总公式
  // if (this.tablePlugins.groupFormula) {
  //   //如果设置了group汇总公式那么group汇总使用定义的公式
  //   _.forEach(this.tablePlugins.groupFormula, (m, column) => {
  //     this.tablePlugins.groupSummary[column] = function(c, ac)  {
  //       return summaryFormula(c, ac, m)
  //     }
  //   });
  // }

  //对于有dataType插件的列，将会对该字段数据强制转换
  if (this.tablePlugins.dataType) {
    _.forEach(this.tablePlugins.dataType, (v, column) => {
      switch(v) {
        case 'number':
          if(!this.tablePlugins.format[column]) {
            this.tablePlugins.format[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return numeral(v).format('0,0.[00]')
            }
          }

          if(!this.tablePlugins.summaryFormat[column]) {
            this.tablePlugins.summaryFormat[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return numeral(v).format('0,0.[00]')
            }
          }
        break;

        case 'currency':
          if(!this.tablePlugins.format[column]) {
            this.tablePlugins.format[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return `$${numeral(v).format('0,0.00')}`
            }
          }

          if(!this.tablePlugins.summaryFormat[column]) {
            this.tablePlugins.summaryFormat[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return `$${numeral(v).format('0,0.00')}`
            }
          }
          break;

        case 'percentage':
          if(!this.tablePlugins.format[column]) {
            this.tablePlugins.format[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return `${numeral(v).format('0,0.00')}%`
            }
          }

          if(!this.tablePlugins.summaryFormat[column]) {
            this.tablePlugins.summaryFormat[column] = function (v) {
              if(isEmpty(v)) {
                return "-"
              }
              return `${numeral(v).format('0,0.00')}%`
            }
          }
        break;

        case 'attribute': //默认是属性模式
        default:
          if(!this.tablePlugins.format[column]) {
            this.tablePlugins.format[column] = function (v) {
              v = isEmpty(v) ? "-" : v
              return v
            }
          }

          if(!this.tablePlugins.summaryFormat[column]) {
            this.tablePlugins.summaryFormat[column] = function (v) {
              v = isEmpty(v) ? "-" : v
              return v
            }
          }
      }
    });
  }

  //对于有default插件的列，将会对该字段数据判断，如果是undefined/null/空字符串，那么用默认值填充
  // if (this.tablePlugins.default) {
  //   _.forEach(this.tablePlugins.default, (dv, column) => {
  //     if(!this.tablePlugins.formula[column]) {
  //       this.tablePlugins.formula[column] = dv
  //     }
  //   })
  // }
}
