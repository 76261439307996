import Vue from "vue";
import { defineStore } from "pinia";
import defaultSettings from "@/settings";
import Cookies from "js-cookie";
import _ from "lodash";
import dayjs from "dayjs";
import {
  get_accessed_routes,
  get_authority_routes,
  set_accessed_routes,
  set_authority_routes,
} from "@src/utils/auth";

import {
  httpPermissionAllows,
  httpPermissionGet,
  httpPermissionMessages,
  httpPermissionModulesLogin,
  httpSignIn,
  httpSignAutoLogin,
} from "@/api/user";
import {
  get_allows,
  get_consts,
  get_messages,
  get_modules,
  get_rules,
  get_user,
  getToken,
  removeToken,
  set_allows,
  set_consts,
  set_messages,
  set_modules,
  set_rules,
  set_user,
  setToken,
} from "@/utils/auth";

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings;
function filterRoutes(modules) {
  //多平台link 修改为单平台link
  _.map(modules, (item) => {
    let newLink = "";
    let { link } = item;
    if (!link) {
      return;
    }
    newLink = link.split("#")[1];
    if (newLink.includes("atd")) {
      newLink = newLink.split("atd")[1];
    } else if (newLink.includes("platform")) {
      newLink = newLink.split("platform")[1];
    }
    item.newLink = newLink;
  });

  let menus = [];

  let parentMenus = _.filter(modules, { parentId: String(0) });

  // 尊重历史代码 和原来保持一致 我也不知道为啥要这样
  parentMenus = parentMenus.filter((item) => {
    return item.menu !== "0";
  });

  _.map(parentMenus, (module) => {
    let childrenMenus = _.filter(modules, {
      parentId: String(module.id),
      menu: "1",
    });
    if (module.name === "效果报表") {
      _.forEach(childrenMenus, (item) => {
        if (item.name === "电商报表") {
          let itemChildrenMenus = _.filter(modules, {
            parentId: String(item.id),
            menu: "1",
          });
          item.children = itemChildrenMenus;
        }
      });
    }
    module.children = childrenMenus;
    menus.push(module);
  });

  return menus;
}
// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id

let userId = JSON.parse(localStorage.getItem("system_user"))?.id;

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    overviewFlag:
      localStorage.getItem(`overviewFlag${userId}`) == "true" ? true : false,
  }),
  actions: {
    toggleOverviewFlag() {
      this.overviewFlag = !this.overviewFlag;
      localStorage.setItem(`overviewFlag${userId}`, this.overviewFlag);
    },
  },
});

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    fixedHeader: fixedHeader,
    tagsView: tagsView,
    showSettings: showSettings,
    sidebarLogo: sidebarLogo,
    anginReport: 0,
    cohortReport: 0,
    isConfigReport: false,
    isLoading: false,
  }),
  actions: {
    handleIsConfigReport(flag) {
      this.isConfigReport = flag;
    },
    handleAnginReport(num) {
      this.anginReport = num;
    },
    handleCohortReport(num) {
      this.cohortReport = num;
    },
    handleIsLoading(flag) {
      this.isLoading = flag;
    },
    handleSidebarLogo(flag) {
      this.sidebarLogo = flag;
    },
    handleShowSettings(flag) {
      this.showSettings = flag;
    },
  },
});

export const useAppStore = defineStore("app", {
  state: () => ({
    sidebar: {
      opened: Cookies.get("sidebarStatus")
        ? !!+Cookies.get("sidebarStatus")
        : true,
      withoutAnimation: false,
    },
    device: "desktop",
    size: Cookies.get("size") || "medium",
  }),
  actions: {
    handleToggleSidebar() {
      this.sidebar.opened = !this.sidebar.opened;
      this.sidebar.withoutAnimation = false;
      if (this.sidebar.opened) {
        Cookies.set("sidebarStatus", 1);
      } else {
        Cookies.set("sidebarStatus", 0);
      }
    },
    handleCloseSidebar: (withoutAnimation) => {
      Cookies.set("sidebarStatus", 0);
      this.sidebar.opened = false;
      this.sidebar.withoutAnimation = withoutAnimation;
    },
    handleToggleDevice: (device) => {
      this.device = device;
    },
    handleSetSize: (size) => {
      this.size = size;
      Cookies.set("size", size);
    },
  },
});
export const usePermissionStore = defineStore("permission", {
  state: () => ({
    permission_routes: JSON.parse(get_accessed_routes() || "{}"),
    authorityRoutes: JSON.parse(get_authority_routes() || "[]"),
  }),
  actions: {
    handleSetRoutes(routes) {
      this.permission_routes = routes;
    },
    handleGenerateRoutes(modules) {
      return new Promise((resolve) => {
        //对模块转换为数据集并根据 sort排序
        modules = _.sortBy(_.map(modules), function (o) {
          return _.toInteger(o.sort);
        });
        let accessedRoutes = filterRoutes(modules);

        this.handleSetRoutes(accessedRoutes);

        set_accessed_routes(JSON.stringify(accessedRoutes));

        resolve(accessedRoutes);
      });
    },
    handleAuthorityRoutes(modules) {
      return new Promise((resolve) => {
        let _routes = [];

        modules.forEach((item) => {
          if (item.link.length > 2) {
            let smallRoute = item?.link?.split("#")[1].slice(4);
            _routes.push(smallRoute);
          }
        });
        this.authorityRoutes = _routes;
        set_authority_routes(JSON.stringify(_routes));
        resolve();
      });
    },
  },
});

export const useUserStore = defineStore("user", {
  state: () => ({
    token: getToken(),
    name: "",
    avatar: "",
    introduction: "",
    roles: [], //以上系统自带下面是新增
    modules: JSON.parse(get_modules() || "[]") || [],
    rules: JSON.parse(get_rules() || "[]") || [],
    allows: JSON.parse(get_allows() || "[]") || [],
    consts: JSON.parse(get_consts() || "[]") || [],
    user: JSON.parse(get_user() || "[]") || {},
    messages: JSON.parse(get_messages() || "[]") || {},
  }),
  actions: {
    handleSetToken(token) {
      this.token = token;
    },
    handleSetIntroduction(introduction) {
      this.introduction = introduction;
    },
    handleSetName(name) {
      this.name = name;
    },
    handleSetAvatar(avatar) {
      this.avatar = avatar;
    },
    handleSetRoles(roles) {
      this.roles = roles;
    },
    // 以下是新系统自定义
    handleSetModules(modules) {
      this.modules = modules;
    },
    handleSetRules(rules) {
      this.rules = rules;
    },
    handleSetAllows(allows) {
      this.allows = allows;
    },
    handleSetConsts(consts) {
      this.consts = consts;
    },
    handleSetUser(user) {
      this.user = user;
    },
    handleSetMessages(messages) {
      this.messages = messages;
    },
    handleLogin(userInfo, isSilent) {
      const { username, password } = userInfo;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let userTokenInfo = {};
          if (isSilent) {
            userTokenInfo = await httpSignAutoLogin({ ...userInfo });
          } else {
            userTokenInfo = await httpSignIn({
              username: username.trim(),
              password: password,
            });
          }

          setToken(userTokenInfo.token);

          let messages = await httpPermissionMessages([
            "atd",
            ["user", "role", "modules", "common", "page"],
            ["zh_cn", "en"],
          ]);

          let consts = await httpPermissionGet([
            ["atd_warning_setting", "atd_all_config", "env"],
          ]);
          let allows = await httpPermissionAllows();
          let modules = await httpPermissionModulesLogin([]);
          modules["module_list"] = modules["module_list"].map((item) => {
            item["id"] = item["id"].toString();
            item["idPath"] = item["id"].toString();
            item["parentId"] = item["parentId"].toString();
            item["menu"] = item["menu"].toString();
            return item;
          });

          let user = userTokenInfo;
          Vue.prototype.$user = user;
          this.handleSetToken(userTokenInfo.token);
          this.handleSetModules(modules["module_list"]);
          this.handleSetRules(modules["rule_list"]);
          this.handleSetAllows(allows);
          this.handleSetConsts(consts);
          this.handleSetUser(user);
          this.handleSetMessages(messages);

          set_modules(JSON.stringify(modules["module_list"]));
          set_rules(JSON.stringify(modules["rule_list"]));
          set_allows(JSON.stringify(allows));
          set_consts(JSON.stringify(consts));
          set_user(JSON.stringify(user));
          set_messages(JSON.stringify(messages));

          let permissionStore = usePermissionStore();
          permissionStore.handleGenerateRoutes(modules["module_list"]);
          permissionStore.handleAuthorityRoutes(modules["module_list"]);

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    // user logout
    handleLogout() {
      return new Promise((resolve) => {
        this.handleSetToken("");
        this.handleSetRoles([]);
        removeToken();
        resolve();
      });
    },
    handleResetToken() {
      return new Promise((resolve) => {
        this.handleSetToken("");
        this.handleSetRoles([]);
        removeToken();
        resolve();
      });
    },
  },
});

export const useGoogleAdStore = defineStore("googleAd", {
  state: () => ({
    accountId: "", // 账户id
    tmp_id: "", // 后端返回字段的配合后端
    campaignId: "",
    adSetId: "",
    activeNavId: "",
    languageList: [], // 语言列表
    geoList: [], // 地理位置列表
    campaignConfig: {}, // google 默认配置参数
    // 应用的form
    applyForm: {
      targetTypeId: 6, // 选择您的目标 id
      activeApply: true, // 选择广告系列类型
      applyInstall: 1, // 选择广告系列子类型
      applyType: "", // 选择您的移动应用采用的平台 IOS ||  Android
      app_id: "", //  应用iD
      campaignName: "", // 广告系列名称
    },
    // 广告系列form
    campaignForm: {
      resource: "1", //第一区域
      startDate: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
      endDate: "",
      geoRadio: "1", // 1 所有国家 2 自定义国家
      selectCity: [], //选中地国家和城市
      excludeCity: [], //排除的国家或者城市
      checkLanguageId: [], // 选中的语言
      dateRadio: "1", // 1 结束时间无 2 结束时间自定义设置
      budget: "",
      goal: "",
      install_action: null,
      user_type: "0",
      setUserTargetMoney: true,
      conversion_action: null, //哪些转化操作对您最为重要
      perInstallationBudget: 0, //目标每次安装费用
      targetReturnRate: 0, //目标广告支出回报率
      costPerConversion: 0, // 目标每次转化费用
    },
    // 广告组form
    adGroupForm: {
      name: "Ad group 3",
      titleList: [{ value: "" }, { value: "" }],
      descriptionsList: [{ value: "" }],
      checkedMateriaImg: [],
      checkedMateriaVideo: [],
    },
  }),
  actions: {
    handleSetAccountId(id) {
      this.accountId = id;
    },
    handleSetCampaignId(id) {
      this.campaignId = id;
    },
    handleSetAdSetId(id) {
      this.adSetId = id;
    },
    handleTmp_id(id) {
      this.tmp_id = id;
    },
    handleGeoList(list) {
      this.geoList = list;
    },
    handleCampaignConfig(config) {
      this.campaignConfig = config;
    },
    handleLanguageList(list) {
      this.languageList = list;
    },
    handleSetAdGroupForm(type, value) {
      this.adGroupForm[type] = value;
    },
    handleApplyFormData(type, value) {
      this.applyForm[type] = value;
    },
    handleCampaignFormData(type, value) {
      this.campaignForm[type] = value;
    },
    toggleOverviewFlag() {
      this.overviewFlag = !this.overviewFlag;
    },
    handleSetActiveNavId(id) {
      this.activeNavId = id;
    },
  },
});

export const useGoogleStore = defineStore("google", {
  state: () => ({
    f_id: "",
    accountId: "", // 账户id
    id: "", //  模版ID
    languageList: [], // 语言列表
    geoList: [], // 地理位置列表
    campaignConfig: {}, // google 默认配置参数
    googleForm: {
      app_id: "", //  应用iD
      folder_id: "", // 选择的文件夹
      gg_user_id: "", //选择的账户
      material_nums: 1, //素材数量
      task_start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"), //任务开始时间
      terminationTime: "", // 投放结束时间  end_date
      execute_rate: 1, // 执行任务时间
      execute_time: "", //具体时间
      campaign_status: false, //campaign状态 status || campaign_status
      campaignName: [], //name
      os: "",
      startDate: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
      endDate: "",
      goal: "",
      install_action: null,
      user_type: "0",
      conversion_action: null, //哪些转化操作对您最为重要
      selectCity: [], //选中地国家和城市
      excludeCity: [], //排除的国家或者城市
      language: [], // 选中的语言
      target_value: 0, //
      campaign_budget: "",
      adGroupName: [],
      titleList: [{ value: "" }, { value: "" }],
      descriptionsList: [{ value: "" }],
      user_list: [],
      geoRadio: "1", // 1 所有国家 2 自定义国家
      materialUploadTimeRadio: true,
      material_read_date: "", // 素材上传时间
      material_past_days: true,
      nextCreateTIme: "", // 下次创建时间
      top_goal: 6, // 选择您的目标 id
      top_campaign_p_type: 1,
      top_campaign_type: 1, // 选择广告系列子类型
      dateRadio: "1", // 1 结束时间无 2 结束时间自定义设置
      material_list: [], //图片的素材
      final_app_urls: "",
      user_list_check: [],
    },
  }),
  actions: {
    handleGoogleForm(type, value) {
      this.googleForm[type] = value;
    },
    // 设置模版id
    handleSetTemplateId(id) {
      this.id = id;
    },
    // 设置account
    handleSetAccountId(accountId) {
      this.accountId = accountId;
    },
    handleSetFId(f_id) {
      this.f_id = f_id;
    },
    // 设置地理位置
    handleGeoList(list) {
      this.geoList = list;
    },
    // 设置语言
    handleLanguageList(list) {
      this.languageList = list;
    },
    // 设置配置信息
    handleCampaignConfig(config) {
      this.campaignConfig = config;
    },
  },
});

export const useTiktokCreateStore = defineStore("tiktokCreateStore", {
  state: () => ({
    id: null,
  }),
  actions: {
    setTempId(id) {
      this.id = id;
    },
    destroy(){
      this.id=null
    }
  },
});
