<template>
  <div class="platform-table-group-columns">
    <el-dialog
      title="自定义分组"
      :visible.sync="dialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <div class="dailog-body">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="col-height">
              <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText"
                style="margin-bottom: 10px"
                clearable
              >
              </el-input>
              <div class="tree-height">
                <el-tree
                  :data="treeNode"
                  show-checkbox
                  default-expand-all
                  node-key="id"
                  ref="tree"
                  highlight-current
                  :props="defaultProps"
                  @check-change="treeNodeCheck"
                  :filter-node-method="filterNode"
                >
                </el-tree>
              </div></div
          ></el-col>
          <el-col :span="12">
            <div class="col-height" style="overflow: auto">
              <draggable
                :list="listNode"
                :disabled="!enabled"
                class="list-group"
                ghost-class="card-ghost"
                @start="dragging = true"
                @end="dragging = false"
              >
                <el-card shadow="hover" v-for="v in listNode" :key="v">
                  <span>{{ getPlatformTable().getColumnLabel(v) }}</span>
                  <span style="float: right;">
                    <span @click="setTopNode(v)" class="btn"
                      ><i class="mdi mdi-format-vertical-align-top" style="padding: 3px"></i
                    ></span>
                    <span @click="setBottomNode(v)" class="btn"
                      ><i class="mdi mdi-format-vertical-align-bottom" style="padding: 3px"></i
                    ></span>
                    <span @click="removeNode(v)" class="btn"
                      ><i class="el-icon-close" style="padding: 3px"></i
                    ></span>
                  </span>
                </el-card>
              </draggable>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>

    <el-button @click="customMethod"><i class="fa fa-tasks"></i></el-button>
  </div>
</template>

<script>
import md5 from "crypto-js/md5"
import draggable from "vuedraggable";
import fastCopy from "fast-copy";
export default {
  props: {
    columns: {},
    value: {
      required: true,
    },
  },
  components: {
    draggable,
  },
  watch: {
    columns: {
      handler(columns) {
        this.treeData = this.treeDataMarker(columns)
        this.treeNode = this.treeNodeMarker(fastCopy(this.treeData))
      },
      immediate: true,
      deep: true,
    },

    listNode (n, o) {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
        this.$refs.tree.setCheckedKeys(n);
      });
    },

    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    treeDataMarker(columns) {
      let treeData = []

      for (let c in columns) {
        let category = columns[c].category ?? ''
        let categoryNode = category.split('.')
        let categoryNodeId = category ? `$__${md5(category)}` : '$__00000000000000000000000000000000'

        _.forEach(categoryNode, (v, k) => {
          if(v) {
            let thisPath = _.join(_.take(categoryNode, k + 1), '.')
            let thisId = `$__${md5(thisPath)}`

            //如果已经包含该文件夹那么不再添加到文件夹节点中
            let parentPath = _.join(_.take(categoryNode, k), '.')
            let parentId = k > 0 ? `$__${md5(parentPath)}`: '$__00000000000000000000000000000000'

            if(!_.find(treeData, {id:thisId})) {
              treeData.push({
                id: thisId,
                label: v,
                parentId: parentId,
              })
            }
          }
        })

        treeData.push({
          id: c,
          label: this.getLabel(c),
          parentId: categoryNodeId,
        })
      }

      return treeData
    },
    treeNodeMarker(nodes, parentId = '$__00000000000000000000000000000000') {
      let subNodes = _.filter(nodes, {parentId:String(parentId)});

      if(!_.size(subNodes)) {
        return nodes;
      }

      let tn = _.map(subNodes, (node) => {
          let childNodes = _.filter(nodes, {parentId:String(node.id)});
          if(_.size(childNodes)) {
            node.children = this.treeNodeMarker(nodes, node.id);
            return node
          } else {
            return node
          }
      })

      return tn
    },
    filterNode(value, data) {
      if (!value) return true;

      let search = new RegExp(_.escapeRegExp(value), "i");
      return data.label.search(search) != -1;
    },
    getPlatformTable() {
      return this.$parent.$parent.$parent
    },
    getLabel(column) {
      if(column.indexOf('$__') === 0 || column == null) {
        return column
      } else {
        return this.getPlatformTable().getColumnName(column);
      }
    },
    save() {
      this.$emit("input", this.listNode);
      this.dialog = false;
    },
    customMethod() {
      //每次新打开窗口拖动列表数据根据原始数据重新排列
      this.listNode = _.clone(this.value);

      this.dialog = true;
    },
    treeNodeCheck(node, checked) {
      //如果不是父级虚拟节点才勾选到列表中
      if(node.id.indexOf("$__") !== 0) {
        //如果是选中
        if(checked) {
          if(!_.includes(this.listNode, node.id)) {
            //改为从顶部压入顺序 最新勾选放最前面
            this.listNode.unshift(node.id)
          }
        } else { //未选中
          if(_.includes(this.listNode, node.id)) {
            this.$delete(this.listNode, _.indexOf(this.listNode, node.id))
          }
        }
      }
    },

    removeNode(column) {
      if(_.includes(this.listNode, column)) {
        this.$delete(this.listNode, _.indexOf(this.listNode, column))
      }
    },
    setTopNode(column) {
      this.removeNode(column)
      this.$nextTick(() => {
        this.listNode.unshift(column)
      })
    },
    setBottomNode(column) {
      this.removeNode(column)
      this.$nextTick(() => {
        this.listNode.push(column)
      })
    },
  },
  data() {
    return {
      dialog: false,
      enabled: true,
      filterText: "",
      dragging: false,
      treeNode: [],
      treeData: [],
      listNode: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/var.scss";
.platform-table-group-columns {
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .dailog-body {
    .col-height {
      height: 45vh;
    }
    .tree-height {
      height: calc(100% - 46px);
      overflow: auto;
    }
    .card-ghost {
      opacity: 0.8;
      color: #fff;
      background: $--color-primary;
    }
    .el-card {
      cursor: move;
      margin: 0 5px 5px 5px;
      .el-card__body {
        line-height: initial;
        padding: 10px;
      }
      .btn {
        cursor: pointer;
      }
    }
  }
}
</style>
