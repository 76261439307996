import request from "@/utils/request";

export function httpApproveList(params) {
  return request({
    url: "api/open-api/custom/event/auth/approveList",
    method: "post",
    data: params,
  });
}

///platform/role/PlatformApi/method/rolelist
export function httpRoleList(params) {
  return request({
    url: "/api/manage/role/list",
    method: "post",
    data: params,
  });
}

export function httpReport(params) {
  return request({
    url: "api/report/userData/getUserDataCharts",
    method: "post",
    data: params,
  });
}

// platform/api/modelapi/Atd_Conn/getList
export function httpUserList(params) {
  return request({
    url: "/api/manage/user/list",
    method: "post",
    data: params,
  });
}

// ginterface/api/manage/company/getListWithCount
export function httpGetListWithCount(params) {
  return request({
    url: "api/manage/company/getListWithCount",
    method: "post",
    data: params,
  });
}


// platform/api/modelapi/Atd_Conn/userAuthIsBingPlatformList
export function httpUserAuthIsBing(params) {
  return request({
    url: "api/manage/userAuth/isBing",
    method: "post",
    data: params,
  });
}

// platform/api/modelapi/Atd_Conn/getUserWechatAlarmCount
export function httpGetUserWechatAlarmCount(params) {
  return request({
    url: "/api/manage/company/getUserWechatAlarmCount",
    method: "post",
    data: params,
  });
}


// platform/api/modelapi/Company/getList
export function httpCompanyList(params) {
  return request({
    url: "/api/manage/company/list",
    method: "post",
    data: params,
  });
}


// platform/api/modelapi/User/add
export function httpUserAdd(params) {
  return request({
    url: "/api/manage/user/add",
    method: "post",
    data: params,
  });
}


export function httpUserRemove(params) {
  return request({
    url: "/api/manage/user/remove",
    method: "post",
    data: params,
  });
}

export function httpUserEdit(params) {
  return request({
    url: "/api/manage/user/edit",
    method: "post",
    data: params,
  });
}

export function httpNewUserEdit(params) {
  return request({
    url: "/api/open-api/custom/event/auth/new/edit",
    method: "post",
    data: params,
  });
}

export function httpNewUserAdd(params) {
  return request({
    url: "/api/open-api/custom/event/auth/new/add",
    method: "post",
    data: params,
  });
}

export function httpUserGet(params) {
  return request({
    url: "/api/manage/user/get",
    method: "post",
    params: params,
  });
}


// platform/api/modelapi/Atd_Conn/sharedAccountList
export function httpSharedAccountList(params) {
  return request({
    url: "/api/ads/shared/account/list",
    method: "post",
    data: params,
  });
}


// /api/modelapi/Atd_Conn/sharedAccountLis
export function httpSharedAccountLis(params) {
  return request({
    url: "",
    method: "post",
    data: params,
  });
}

// /platform/api/modelapi/Company/add
export function httpCompanyAdd(params) {
  return request({
    url: "api/manage/company/add",
    method: "post",
    data: params,
  });
}

export function httpUpdateName(params) {
  return request({
    url: "api/manage/company/updateName",
    method: "post",
    data: params,
  });
}

export function httpShareAccountList(params) {
  return request({
    url: "/api/ads/shared/account/list",
    method: "post",
    data: params,
  });
}


export function httpPermissionModules(params) {
  return request({
    url: "api/manage/permission/modules",
    method: "post",
    data: params,
  });
}

// /platform/api/modelapi/Atd_Open/customEventAuthEditStatus

export function httpCustomEventAuthEditStatus(params) {
  return request({
    url: "/api/open-api/custom/event/auth/editStatus",
    method: "post",
    data: params,
  });
}


// /platform/api/modelapi/User/setPassword


export function httpSetPassword(params) {
  return request({
    url: "api/manage/user/setPassword",
    method: "post",
    data: params,
  });
}


// 角色管理

export function httpUserRoleRoleList(params) {
  return request({
    url: "api/manage/userRole/rolelist",
    method: "post",
    data: params,
  });
}

export function httpUserRoleCreate(params) {
  return request({
    url: "api/manage/userRole/create",
    method: "post",
    data: params,
  });
}

export function httpUserRoleDel(params) {
  return request({
    url: "api/manage/userRole/del",
    method: "post",
    data: params,
  });
}

export function httpUserRoleEdit(params) {
  return request({
    url: "api/manage/userRole/edit",
    method: "post",
    data: params,
  });
}

export function httpUserRoleView(params) {
  return request({
    url: "api/manage/userRole/view",
    method: "post",
    data: params,
  });
}


export function httpUserRoleGetAllModule(params) {
  return request({
    url: 'api/manage/userRole/getAllModule',
    method: 'post',
    data: params
  })
}



// 系统账号管理

export function httpUserLock(params) {
  return request({
    url: "api/manage/user/lock",
    method: "post",
    data: params,
  });
}


export function httpUserUnlock(params) {
  return request({
    url: "api/manage/user/unlock",
    method: "post",
    data: params,
  });
}


// 获取角色列表新接口

export function httpSearchRolelist(params) {
  return request({
    url: "api/manage/userRole/search_rolelist",
    method: "post",
    data: params,
  });
}


//
export function httpGetBindInfo(params) {
  return request({
    url: "api/manage/user/getBindInfo",
    method: "post",
    data: params,
  });
}


export function httpAccountAuthUnbind(params) {
  return request({
    url: "api/manage/user/accountAuthUnbind",
    method: "post",
    data: params,
  });
}


export function httpShareAccountStop(params) {
  return request({
    url: "/api/ads/share/account/stop",
    method: "post",
    data: params,
  });
}

export function httpManageUserRoleList(params) {
  return request({
    url: "api/manage/userRole/list",
    method: "post",
    data: params,
  });
}

export function httpManageUserRoleUpdate(params) {
  return request({
    url: "api/manage/userRole/update",
    method: "post",
    data: params,
  });
}

export function httpManageUserRoleAdd(params) {
  return request({
    url: "api/manage/userRole/add",
    method: "post",
    data: params,
  });
}

export function httpManageUserRoleDelete(params) {
  return request({
    url: "api/manage/userRole/delete",
    method: "post",
    data: params,
  });
}

