import Vue from "vue";

import spinner from "platform/plugins/spinner";
Vue.use(spinner);

import PathGenerate from "@platform/api/path/generate.js";
import Alarm from "@platform/api/alarm.js";
import Encrypt from "@platform/api/encrypt.js";
import CoreApi from "@platform/api/api.js";

import {
  setupCache,
  buildMemoryStorage,
  defaultKeyGenerator,
  defaultHeaderInterpreter,
} from "axios-cache-interceptor";

import PlatformPluginsHttp from "@platform/plugins/http";

import _ from "lodash";

const http = setupCache(
  // axios instance
  PlatformPluginsHttp.axios,
  // All options with their default values

  {
    // The storage to save the cache data. There are more available by default.
    //
    // https://axios-cache-interceptor.js.org/#/pages/storages
    storage: buildMemoryStorage(),

    // The mechanism to generate a unique key for each request.
    //
    // https://axios-cache-interceptor.js.org/#/pages/request-id
    generateKey: defaultKeyGenerator,

    // The mechanism to interpret headers (when cache.interpretHeader is true).
    //
    // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
    headerInterpreter: defaultHeaderInterpreter,

    // The function that will receive debug information.
    // NOTE: For this to work, you need to enable development mode.
    //
    // https://axios-cache-interceptor.js.org/#/pages/development-mode
    // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=debug
    debug: undefined,

    //不使用 response header 中的缓冲时间控制
    interpretHeader: false,

    // // If the support for ETag and If-None-Match headers is active. You can use a string to
    // // force a custom value for the ETag response.
    etag: false,

    // If we should interpret the If-Modified-Since header when generating a TTL value.
    modifiedSince: false,

    // If we should return a old (possibly expired) cache when the current request failed
    // to get a valid response because of a network error, invalid status or etc.
    staleIfError: false,

    //因为所有接口都是 post 所以要对post请求缓存
    methods: ["post"],

    //默认关闭缓存只有需要缓存的单独设置
    ttl: 0,
  }
);

class PlatformAlarm extends Alarm {
  notify(message, code, type, service) {
    let lang = { error: "错误", warning: "警告" };
    let imessage = `[${service}][${code}] ${message}`;
    new Vue().$notify[type]({
      title: lang[type],
      message: imessage,
      duration: 0,
    });
  }
}

let coreApi = new CoreApi({
  alarm: new PlatformAlarm(),
  encrypt: new Encrypt(),
  pathGenerate: new PathGenerate(),
  http: http,
});

export default {
  install: function (Vue, name) {
    //该方法将自动添加 loading 画面并在操作结束后关闭
    Vue.prototype.$load = function (moduleName, opt = {}) {
      opt = _.assign({ target: "globalLoading" }, opt);
      this.$spinner.show(opt);
      let that = this;
      return new Proxy(
        {},
        {
          get: function (target, method) {
            return function () {
              return coreApi
                .$api(moduleName, opt)
                [method].apply(coreApi, arguments)
                .finally(function () {
                  opt.delay = opt.delay || 0;
                  setTimeout(() => {
                    that.$spinner.hide(opt);
                  }, opt.delay);
                });
            };
          },
        }
      );
    };

    Vue.prototype.$api = function (moduleName, config = {}) {
      return coreApi.$api.apply(coreApi, arguments);
    };

    Vue.prototype.$apiStorage = PlatformPluginsHttp.axios.storage;
  },
};
