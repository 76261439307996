import { useUserStore } from "@/store/pinia";
export function getDomain() {
  if (process.env.NODE_ENV == "development") {
    return process.env.VUE_APP_BASE_URL;
  } else {
    let domain = "https://" + window.location.host + "/interface/";
    return domain;
  }
}

export function getUploadUrlBaseURL() {
  let userStore = useUserStore();
  return userStore?.user?.upload_url || "";
}

let usedNums = [];

// 生成不重复的随机数的函数
export function getUniqueRandomNumber(min, max) {
  let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

  // 如果生成的随机数已经存在于数组中，则递归调用该函数重新生成
  if (usedNums.includes(randomNum)) {
    return getUniqueRandomNumber(min, max);
  }

  // 将新生成的随机数添加到数组中，并返回该随机数
  usedNums.push(randomNum);
  return randomNum;
}

export function handleOnlyIsBoke() {
  let bokeDomain = "tongtut.com";
  let currentDomain = window.location.hostname;
  return currentDomain.includes(bokeDomain);
}
