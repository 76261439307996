/**
 * @export 子集排序
 * @param {*} row 基础数据
 * @param {*} column 单列排序
 * @param {*} order 排序方式(desc/asc)
 */
let sort = function (row,column,order){
    row = _.orderBy(row, [column], [order]);
    Object.values(row).forEach((item)=>{ //https://www.measurethat.net/Benchmarks/ShowResult/258730
      if(item.hasOwnProperty('children')&&item.children.length>0){
          item.children = _.orderBy(item.children, [column], [order]);
          sort(item.children,column,order)
      }
    })
    return row
}
export default sort
